<template>
  <div v-if="$route.params.tab == 'settings'">
    <v-row class="mt-2 mb-n4">
      <v-col cols="12" md="6">
        <h2 class="text-uppercase page-title mb-4">Settings</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(section, i) in sections"
        :key="i"
        cols="12"
        md="6"
        class="d-flex"
      >
        <settings-section :section="section" />
      </v-col>
    </v-row>
    <add-edit-form />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import SettingsSection from "./SettingsSection.vue"

export default {
  name: "settings",
  components: {
    AddEditForm,
    SettingsSection,
  },
  data() {
    return {
      sections: [
        {
          title: "Organization Hours",
          category: "hours",
        },
        {
          title: "Pricing",
          category: "fees",
        },
        {
          title: "Colors",
          category: "colors",
        },
        {
          title: "Display",
          category: "display",
        },
        {
          title: "About",
          category: "about",
        },
        {
          title: "Delivery",
          category: "delivery",
        },
        {
          title: "Referral",
          category: "referral",
        },
      ],
    }
  },
}
</script>
