<template>
  <v-list-item class="d-flex flex-no-wrap align-center">
    <v-row align="center">
      <v-col cols="5" class="pa-0">
        <v-list-item-title class="text-body-2">
          {{ title }}
        </v-list-item-title>
      </v-col>
      <v-col cols="7" class="pa-1">
        <v-img
          v-if="type === 'image'"
          :src="value"
          width="100px"
          height="100px"
        ></v-img>
        <v-col v-else cols="12" class="py-0">
          <v-list-item-subtitle
            v-if="type !== 'color'"
            class="subtitle-2 lighttext--text"
          >
            {{ value }}
          </v-list-item-subtitle>
          <v-btn
            v-else
            x-small
            elevation="0"
            :color="value"
            style="border: solid 1px #ddd"
            :style="{
              backgroundColor: `${value} !important`, // keeps background color when disabled
            }"
            disabled
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-list-item>
</template>

<script>
export default {
  name: "info-row",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
  },
}
</script>
