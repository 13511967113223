<template>
  <div v-if="$route.params.tab === 'loyalty'">
    <page-component
      pageTitle="Loyalty"
      :headers="headers"
      :items="loyaltyRewards"
      :loadingData="loading"
      :buttons="buttons"
    >
      <template v-slot:[`item.type`]="{ item }">
        <v-row no-gutters>{{ getItemType(item.type) }}</v-row>
        <v-row no-gutters style="color: var(--v-lighttext-base)">
          {{ getItemDetails(item) }}
        </v-row>
      </template>
      <template v-slot:[`item.isEnabled`]="{ item }">
        <v-switch
          v-model="item.isEnabled"
          color="success"
          small
          @click="toggleSwitchStatus(item)"
        ></v-switch>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn
          v-if="!item.isDeleted"
          type="edit"
          @click="editLoyaltyReward(item)"
        />
        <nxg-action-btn
          v-if="!item.isDeleted"
          type="delete"
          item-type="LOYALTY_REWARD"
          :item="item"
        />
      </template>
    </page-component>
    <add-edit-form
      v-if="$route.params.form == 'AddEditForm'"
      @close="fetchLoyaltyRewards()"
    />
    <options-form
      v-if="$route.params.form == 'Options'"
      @close="fetchLoyaltyRewards()"
    />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import OptionsForm from "./OptionsForm.vue"
import { mapState, mapActions } from "vuex"
import PageComponent from "../../shared/PageComponent.vue"
import "moment-timezone"

export default {
  name: "loyalty",
  components: {
    AddEditForm,
    PageComponent,
    OptionsForm,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapState(["loyaltyRewards", "firebaseRefs", "menuItems"]),
    buttons() {
      return [
        { text: "+ Add Loyalty Reward", to: { params: { form: "AddEditForm" } } },
        { text: "Loyalty Options", to: { params: { form: "Options" } } },
      ]
    },
    headers() {
      const headers = [
        { text: "Type", value: "type", class: "header-style" },
        { text: "Points Cost", value: "pointsCost", class: "header-style" },
        {
          text: "Enabled",
          value: "isEnabled",
          class: "header-style",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "header-style",
        },
      ]
      return headers
    },
  },
  async mounted() {
    if (!this.loyaltyRewards?.length) {
      await Promise.all(this.fetchLoyaltyRewards(), this.fetchInventoryData())
    } else {
      await this.fetchInventoryData()
    }
    this.loading = false
  },
  methods: {
    ...mapActions(["fetchInventoryData", "fetchLoyaltyRewards"]),
    async editLoyaltyReward(reward) {
      await this.$store.commit("setItemToEdit", reward)
      this.$router.push({ params: { form: "AddEditForm", id: reward.id } })
    },
    async toggleSwitchStatus(item) {
      this.$store
        .dispatch("toggleLoyaltyRewardStatus", {
          isEnabled: item.isEnabled,
          id: item.id,
        })
        .catch(err => {
          this.mainErrorMessage = err
        })
    },
    getMenuItemName(menuItemId) {
      return this.menuItems.find(item => item.id == menuItemId)
        ? this.menuItems.find(item => item.id == menuItemId).name
        : "None"
    },
    getItemType(str) {
      const firstWord = str.match(/([A-Z]?[^A-Z]*)/g)[0]
      const secondWord = str.match(/([A-Z]?[^A-Z]*)/g)[1]
      return (
        firstWord.charAt(0).toUpperCase() + firstWord.slice(1) + " " + secondWord
      )
    },
    getItemDetails(item) {
      return item.type === "fixedDiscount"
        ? `Discount: ${item.fixedDiscountAmount}%`
        : item.type === "freeItem"
        ? `Item: ${this.getMenuItemName(item.menuItemId)}`
        : ""
    },
  },
}
</script>

<style></style>
