<template>
  <v-card class="mt-2 mb-n2 flex-grow-1 py-2" outlined>
    <v-card-title class="text-h6 text-uppercase my-n3">
      <v-container class="d-flex justify-space-between pa-0">
        <span class="flex-grow-1">{{ section.title }}</span>
        <v-card-actions>
          <v-btn
            :to="{
              params: { form: 'AddEditForm', id: section.category },
            }"
            color="primary"
            class="my-n2"
          >
            EDIT
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card-title>
    <v-card-text>
      <weekly-hours
        v-if="section.category === 'hours'"
        v-model="parsedRows"
        disabled
        class="mt-4"
      />
      <v-list
        v-else
        lines="one"
        :style="{
          backgroundColor: 'transparent !important', // overrides dark bg theme color
        }"
      >
        <settings-row
          v-for="(row, i) in parsedRows"
          :key="i"
          :title="row.title"
          :value="getFormattedValue(row.value, row.type)"
          :type="row.type"
        />
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import SettingsRow from "./SettingsRow"
import WeeklyHours from "@/AuthenticatedContent/shared/forms/datetime/WeeklyHours.vue"
import { mapState } from "vuex"
import getSettingsSection from "./mixins"
import formatPrice from "@/mixins/formatPrice"

export default {
  name: "settings-section",
  components: {
    SettingsRow,
    WeeklyHours,
  },
  mixins: [getSettingsSection, formatPrice],
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["settings"]),
    parsedRows() {
      if (this.section.category === "hours") {
        return this.getSettingsSection(this.section.category)?.[0]?.value
      }
      return this.getSettingsSection(this.section.category)
    },
  },
  methods: {
    getFormattedValue(value, type) {
      if (value == null) {
        return "-"
      } else if (type === "percent") {
        return value + "%"
      } else if (type === "dollar") {
        return this.formatPrice(value)
      }
      return value
    },
  },
}
</script>
