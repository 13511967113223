<template>
  <custom-dialog
    :visible="$route.params.form == 'AddEditForm'"
    title="Settings"
    :closeDisabled="closeDisabled"
    :awaitingResponse="awaitingResponse"
    @close="closeForm()"
    @submit="submitHandle"
  >
    <v-row v-if="imageRows.length > 0">
      <v-col
        v-for="(row, i) in imageRows"
        :key="i"
        :cols="12 / imageRows.length"
        class="d-flex flex-column justify-center"
      >
        <v-card-title class="text-h6 text-uppercase d-flex justify-center">
          {{ row.title }}
        </v-card-title>
        <custom-image-upload
          name="image"
          :location="
            row.path.includes('organizationHeader')
              ? 'Organization/hero'
              : 'Organization/logo'
          "
          :imageUrl="row.value || ''"
          allow-edit
          @imageProcessed="row.value = $event"
        />
      </v-col>
    </v-row>
    <div v-for="(row, i) in newSettings" :key="i" class="my-5">
      <weekly-hours
        v-if="$route.params.id === 'hours'"
        v-model="newSettings[0].value"
        :disabled="!newSettings[0].value"
      />
      <v-card v-else-if="row.type !== 'image'" class="elevation-0">
        <v-row v-if="row.type === 'color'" justify="center">
          <v-text-field v-model="row.value" type="text" outlined :label="row.title">
            <template slot="append">
              <v-menu
                ref="colorPickerMenu"
                offset-y
                style="z-index: 200"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    elevation="0"
                    v-bind="attrs"
                    :color="row.value"
                    style="border: solid 1px #ddd"
                    v-on="on"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </v-btn>
                </template>
                <v-card class="align-center">
                  <v-color-picker
                    v-model="row.value"
                    hide-details
                    class="mt-n4"
                    @input="showColorPicker = false"
                  />
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      @click="$refs.colorPickerMenu[i].save(row.value)"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-row>
        <v-row v-else align="center">
          <v-card-subtitle v-if="row.type === 'boolean'">
            {{ row.title }}
          </v-card-subtitle>
          <v-switch
            v-if="row.type === 'boolean'"
            v-model="row.value"
            color="success"
            :label="row.value ? 'Enabled' : 'Disabled'"
            dense
          />

          <v-text-field
            v-else-if="
              row.type === 'percent' ||
              row.type === 'number' ||
              row.type === 'dollar'
            "
            v-model.number="row.value"
            :type="row.type ? (row.type === 'percent' ? 'number' : 'text') : 'text'"
            :suffix="row.type === 'percent' ? '%' : ''"
            outlined
            :label="row.title"
          />
          <v-text-field
            v-else
            v-model="row.value"
            :type="row.type"
            outlined
            :label="row.title"
          />
        </v-row>
      </v-card>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../shared/dialogs/FormDialog.vue"
import { mapState } from "vuex"
import getSettingsSection from "./mixins"
import CustomImageUpload from "../../shared/CustomImageUpload.vue"
import WeeklyHours from "@/AuthenticatedContent/shared/forms/datetime/WeeklyHours.vue"

export default {
  name: "add-edit-form",
  components: {
    CustomDialog,
    CustomImageUpload,
    WeeklyHours,
  },
  mixins: [getSettingsSection],
  data() {
    return {
      newSettings: [],
      closeDisabled: false,
      awaitingResponse: false,
    }
  },

  computed: {
    ...mapState(["settings"]),
    fieldRows() {
      if (this.$route.params.id) {
        return JSON.parse(
          JSON.stringify(this.getSettingsSection(this.$route.params.id)) // resets object referenece every time form closed
        )
      }
      return []
    },
    imageRows() {
      return this.fieldRows.filter(row => row.type === "image")
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          this.prepEdit()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true
      await this.$store.dispatch("updateSettings", this.newSettings).then(() => {
        this.closeDisabled = this.awaitingResponse = false
        if (this.$route.params.form != null) {
          this.$router.push({ params: { form: null, id: null } })
        }
      })
      this.$emit("close")
    },
    closeForm() {
      this.$router.push({ params: { form: null, id: null } })
    },
    prepEdit() {
      this.newSettings = this.fieldRows
    },
  },
}
</script>
