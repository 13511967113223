<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      title="Payment Channel"
      submitLabel="Continue"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      :error="mainErrorMessage"
      @close="closeForm()"
      @submit="submitHandle()"
    >
      <v-row justify="start" class="mt-0">
        <v-col cols="4">
          <v-select
            v-model="restaurantId"
            :items="filteredRestaurants"
            item-text="name"
            item-value="id"
            outlined
            label="Restaurant"
            :error-messages="getFieldError($v.restaurantId)"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="selectedPaymentProvider"
            :items="paymentProviders"
            outlined
            label="Payment Provider"
            :error-messages="getFieldError($v.selectedPaymentProvider)"
          />
        </v-col>
      </v-row>
    </form-dialog>
  </div>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { mapState } from "vuex"
import ChannelsMixins from "./mixins"
import getFieldError from "@/mixins/getFieldError"
import { required } from "vuelidate/lib/validators"

export default {
  name: "add-edit-form",
  components: { FormDialog },
  mixins: [ChannelsMixins, getFieldError],
  props: {
    paymentProviders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      closeDisabled: false,
      awaitingResponse: false,
      restaurantId: "",
      selectedPaymentProvider: "",
      mainErrorMessage: "",
    }
  },
  computed: {
    ...mapState(["restaurants", "itemToEdit", "channels"]),
    editMode() {
      return this.itemToEdit != null
    },
    filteredRestaurants() {
      return this.restaurants.filter(
        restaurant =>
          !this.channels.some(channel => channel.restaurantId === restaurant.id)
      )
    },
  },
  validations() {
    return {
      restaurantId: { required },
      selectedPaymentProvider: { required },
    }
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
          this.closeForm()
        }
      },
    },
  },
  mounted() {
    if (this.paymentProviders.length === 1) {
      this.selectedPaymentProvider = this.paymentProviders[0]
    }
  },
  methods: {
    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.closeDisabled = this.awaitingResponse = false
        return
      }

      try {
        const response = await this.generateConnectedStripeAccount(this.restaurantId)
        window.open(response.data.url)
      } catch (error) {
        this.mainErrorMessage = error.message
        this.closeDisabled = this.awaitingResponse = false
        return
      }

      this.closeDisabled = this.awaitingResponse = false

      this.$emit("close")
      this.closeForm()
    },
    closeForm() {
      this.$router.push({ params: { form: null, id: null } })
    },
    prepEdit() {
      this.restaurantId =
        this.itemToEdit.restaurantId ||
        this.itemToEdit.restaurantData.map(resto => resto.restaurantId)
    },
    clearFields() {
      this.$v.$reset()
      this.restaurantId = ""
      this.mainErrorMessage = ""
      this.selectedPaymentProvider = ""
    },
  },
}
</script>
