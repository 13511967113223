import moment from "moment"

export default {
  methods: {
    getEmptyWeekRange() {
      return {
        sun: [],
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
      }
    },
    getEmptyDateRange() {
      return { start: 0, end: 0 }
    },
    getEmptyDateRangeString() {
      return { start: "00:00", end: "00:00" }
    },
    numberToTime(num, isEndTime = false) {
      if (num) {
        num = parseFloat(num)
        const hour = Math.trunc(num)
        const minute = Math.round((num - hour) * 60)
        return moment({ hour, minute }).format("HH:mm")
      }
      return isEndTime ? "23:59" : "00:00"
    },
    timeToNumber(time) {
      if (time && time.includes(":")) {
        const [hour, minute] = time.split(":")
        return parseFloat(hour) + parseFloat(minute) / 60
      }
      return 0
    },
  },
}
