import { mapState } from "vuex"

export default {
  data() {
    return {
      fieldsBySection: {
        // path dictates object structure in firebase, documents dictates where the field is stored
        hours: [
          {
            title: "",
            type: "hours",
            path: ["hours"],
            documents: ["Public"],
          },
        ],
        fees: [
          {
            title: "Commission",
            type: "percent",
            path: ["organizationFee"],
            documents: ["Organization"],
          },
          {
            title: "GST on Commission",
            type: "percent",
            path: ["organizationFeeGST"],
            documents: ["Organization"],
          },
          {
            title: "PST on Commission",
            type: "percent",
            path: ["organizationFeePST"],
            documents: ["Organization"],
          },
          {
            title: "Other Tax on Commission",
            type: "percent",
            path: ["organizationFeeTax"],
            documents: ["Organization"],
          },
          {
            title: "Organization Tip Split",
            type: "percent",
            path: ["organizationTip"],
            documents: ["Organization"],
          },
          {
            title: "Service Fee",
            type: "percent",
            path: ["serviceFee"],
            documents: ["Public"],
          },
          {
            title: "Service Fee Tax",
            type: "percent",
            path: ["serviceFeeTax"],
            documents: ["Public"],
          },
          {
            title: "Bag Fee Tax",
            type: "percent",
            path: ["bagFee", "taxPercent"],
            documents: ["Public"],
          },
        ],
        colors: [
          {
            title: "Organization Color",
            type: "color",
            path: ["organizationColor"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Organization Text Color",
            type: "color",
            path: ["organizationTextColor"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Kiosk Organization Color",
            type: "color",
            path: ["kioskOrganizationColor"],
            documents: ["Public"],
          },
        ],
        display: [
          {
            title: "Organization Header Image",
            type: "image",
            path: ["organizationHeader"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Organization Logo",
            type: "image",
            path: ["organizationLogo"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Kiosk Logo",
            type: "image",
            path: ["kioskOrganizationLogo"],
            documents: ["Public"],
          },
        ],
        about: [
          {
            title: "Organization Name",
            path: ["organizationName"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Organization Phone Number",
            path: ["organizationPhoneNumber"],
            documents: ["Organization", "Public"],
          },
          {
            title: "Organization Address",
            path: ["organizationAddress"],
            documents: ["Organization", "Public"],
          },
        ],
        delivery: [
          {
            title: "Free Delivery Multiplier",
            type: "number",
            path: ["delivery", "freeDeliveryMultiplier"],
            documents: ["Public"],
          },
          {
            title: "Minimum Order For Free Delivery",
            type: "dollar",
            path: ["delivery", "minimumOrder"],
            documents: ["Public"],
          },
          {
            title: "Pickup Instructions",
            path: ["delivery", "pickupInstructions"],
            documents: ["Public"],
          },
          {
            title: "Action If Undeliverable",
            path: ["delivery", "actionIfUndeliverable"],
            documents: ["Public"],
          },
          {
            title: "Organization Coverage",
            path: ["delivery", "organizationCoverage", "amount"],
            type: "dollar",
            documents: ["Public"],
          },
        ],
        referral: [
          {
            title: "Referral Amount",
            path: ["referralAmount", "amount"],
            type: "dollar",
            documents: ["Public"],
          },
          {
            title: "Referral Organization Coverage",
            type: "percent",
            path: ["referralAmount", "organizationCoveragePercent"],
            documents: ["Public"],
          },
        ],
      },
    }
  },
  computed: {
    ...mapState(["settings"]),
    parsedFields() {
      // fetches firebase settings values based on the path field within each object, if none found, it is not included in the array
      const parsedFieldsBySection = {}
      for (const section in this.fieldsBySection) {
        parsedFieldsBySection[section] = this.fieldsBySection[section].reduce(
          (result, row) => {
            const fieldValue = this.getFieldValue(row, this.settings, row.path)
            result.push({
              ...row,
              value: fieldValue,
            })
            return result
          },
          []
        )
      }

      return parsedFieldsBySection
    },
  },
  methods: {
    getSettingsSection(section) {
      return this.parsedFields[section]
    },
    getFieldValue(row, value, path) {
      // fetches firebase field value recursively based on the path parameter, returns null if value not found
      if (value == null) {
        return null
      }

      if (path.length === 0) {
        return value
      } else {
        let nestedValue = value[path[0]]
        return this.getFieldValue(row, nestedValue, path.slice(1))
      }
    },
  },
}
