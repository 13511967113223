import { functions } from "@/firebaseConfig"
import { httpsCallable } from "firebase/functions"
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["settings", "firebaseRefs", "firebaseGetters"]),
  },
  methods: {
    async generateConnectedStripeAccount(restaurantId) {
      const requestObj = {
        orgId: this.firebaseRefs.organization.id,
        restaurantId,
      }

      const response = await httpsCallable(
        functions,
        "generateConnectedStripeAccount"
      )(requestObj)

      if (response.data.status === "failed") {
        throw new Error(response.data.message)
      }

      return response
    },
  },
}
