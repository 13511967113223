<template>
  <div v-if="$route.params.tab == 'restaurants'">
    <page-component
      pageTitle="Restaurants"
      :headers="headers"
      :items="filteredRestaurants"
      :loadingData="restaurantsLoaded"
      :buttons="buttons"
    >
      <template v-slot:filters[0]>
        <v-checkbox
          v-model="includeDeleted"
          label="Show deleted"
          hide-details
          dense
        />
      </template>
      <template v-slot:[`item.address`]="{ item }">
        {{ formatAddress(item) }}
      </template>
      <template v-slot:[`item.isSnoozed`]="{ item }">
        <div v-if="item.isDeleted">Deleted</div>
        <nxg-snooze-btn v-else type="restaurant" :item="item" show-time />
      </template>
      <!--template v-slot:[`item.enabledFeatures.delivery`]="props">
        <v-switch
          v-model="props.item.enabledFeatures.delivery"
          :disabled="props.item.isSnoozed"
          color="success"
          @change="
            updateDeliveryStatus({
              id: props.item.id,
              val: props.item.enabledFeatures.delivery,
            })
          "
        />
      </template-->
      <template v-slot:[`item.prepTime`]="props">
        {{ props.item.prepTime + " min" }}
      </template>
      <template v-if="userCanEdit" v-slot:[`item.actions`]="{ item }">
        <!--nxg-action-btn
          v-if="importPossible(item.id)"
          type="sync"
            title="Sync menu items from square"
          @click="importMenuItemsFromSquare(item)"
        /-->
        <nxg-action-btn
          v-if="importPossible(item.id) && !item.isDeleted"
          type="sync"
          title="Sync everything from square"
          @click="importAllFromSquare(item)"
        />
        <nxg-action-btn
          v-else-if="!item.isDeleted"
          type="link"
          title="Connect this restaurant to a Square account"
          @click="linkSquare(item)"
        />
        <nxg-action-btn
          v-if="!item.isDeleted"
          type="edit"
          @click="editRestaurant(item)"
        />
        <nxg-action-btn
          v-if="!item.isDeleted"
          type="delete"
          item-type="RESTAURANT"
          :item="item"
        />
      </template>
    </page-component>
    <!-- </v-data-table>
    </v-card> -->
    <add-edit-form
      v-if="$route.params.form == 'AddEditForm'"
      @close="
        $router.push({
          params: { form: null, id: null },
        })
        fetchRestaurants()
      "
    />
    <v-dialog v-model="needsRefresh" persistent retain-focus width="500">
      <v-card>
        <v-card-title>Done</v-card-title>
        <v-card-text class="pb-6">
          <p v-if="restaurantToSync">
            Your restaurant has been successfully connected to Square. Please press
            the button below to import from your Square account now.
          </p>
          <p v-else-if="needsRefreshNew">
            Your restaurant has been successfully created and connected to Square.
            Please press the button below to import from your Square account now.
          </p>
          <p v-else>Press the button below to continue.</p>
          <v-btn
            color="primary"
            :loading="awaitingResponse"
            @click="refreshData(false)"
          >
            Continue
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import { mapGetters, mapState, mapActions } from "vuex"
import PageComponent from "../../shared/PageComponent.vue"
import NxgSnoozeBtn from "../../shared/SnoozeButton.vue"
import "moment-timezone"

export default {
  name: "restaurants",
  components: {
    AddEditForm,
    PageComponent,
    NxgSnoozeBtn,
  },
  data() {
    return {
      includeDeleted: false,
      showConfirmationDialog: false,
      selectedRestaurants: [],
      restaurantEditMode: false,
      searchRestaurants: "",
      restaurantsLoaded: false,
      restaurantStatus: {},
      needsRefresh: false,
      needsRefreshNew: false,
      restaurantToSync: null,
      awaitingResponse: false,
    }
  },
  computed: {
    ...mapGetters(["userCanEdit"]),
    ...mapState(["currentUserRole", "restaurants", "firebaseRefs", "channels"]),
    buttons() {
      if (this.currentUserRole === "Admin") {
        return [
          { text: "+ Add New Restaurant", to: { params: { form: "AddEditForm" } } },
          { text: "+ Add Square Restaurant", click: this.addLinkSquare },
        ]
      } else return []
    },
    headers() {
      const headers = [
        { type: "checkbox", sortable: false, class: "header-style" },
        { text: "Name", value: "name", class: "header-style" },
        { text: "Address", value: "address", class: "header-style" },
        {
          text: "Status",
          value: "isSnoozed",
          class: "header-style",
        },
        // {
        //   text: "Delivery Status",
        //   value: "enabledFeatures.delivery",
        // },
        { text: "Prep Time", value: "prepTime", class: "header-style" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "header-style",
        },
      ]
      return headers
    },
    filteredRestaurants() {
      return this.restaurants.filter(
        restaurant => this.includeDeleted || !restaurant.isDeleted
      )
    },
  },
  methods: {
    ...mapActions([
      "fetchRestaurants",
      "updateDeliveryStatus",
      "deleteRestaurant",
      "fetchAllData",
    ]),
    async refreshAndCheckEmpty() {
      await this.$store.dispatch("fetchRestaurants")
      this.restaurants.forEach(restaurant => {
        if (!(restaurant && restaurant.storeNumber)) {
          this.editRestaurant(restaurant)
        }
      })
    },
    async refreshData() {
      this.awaitingResponse = true
      const restaurantIdsBeforeSync = [
        ...this.restaurants.map(restaurant => restaurant.id),
      ]
      await this.fetchAllData()
      const restaurantsAfterSync = [...this.restaurants]
      const newRestaurants = restaurantsAfterSync.filter(
        restaurant => !restaurantIdsBeforeSync.includes(restaurant.id)
      )
      newRestaurants.forEach(restaurant => {
        this.importAllFromSquare(restaurant)
      })
      if (this.restaurantToSync) {
        this.importAllFromSquare(this.restaurantToSync)
        this.restaurantToSync = null
      }

      if (!this.restaurantToSync && !newRestaurants.length) {
        this.needsRefresh = false
        this.needsRefreshNew = false
      }
      this.awaitingResponse = false
    },
    formatAddress(restaurant) {
      if (restaurant.hasOwnProperty("address")) {
        return [
          restaurant.address.addressLine1,
          restaurant.address.city,
          restaurant.address.provinceState,
          restaurant.address.country,
        ].join(", ")
      } else {
        return "N/A"
      }
    },
    async editRestaurant(restaurant) {
      await this.$store.commit("setItemToEdit", restaurant)
      this.$router.push({ params: { form: "AddEditForm", id: restaurant.id } })
    },
    linkSquare(restaurant) {
      let restaurantId = restaurant.id
      this.restaurantToSync = restaurant
      let randomVal = Math.random().toString(36).slice(2)
      window.open(
        "https://connect.squareup.com/oauth2/authorize?client_id=sq0idp-xOwTGREqCx55nb60JXJ29g&scope=CUSTOMERS_WRITE+CUSTOMERS_READ+PAYMENTS_READ+PAYMENTS_WRITE+ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+INVENTORY_WRITE+ORDERS_READ+ORDERS_WRITE+MERCHANT_PROFILE_READ+MERCHANT_PROFILE_WRITE+LOYALTY_READ+LOYALTY_WRITE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+&session=false&state=" +
          this.firebaseRefs.organization.id +
          "|" +
          restaurantId +
          "|" +
          randomVal,
        "_blank"
      )
      setTimeout(() => {
        this.needsRefresh = true
      }, 500)
    },
    addLinkSquare() {
      let randomVal = Math.random().toString(36).slice(2)
      window.open(
        "https://connect.squareup.com/oauth2/authorize?client_id=sq0idp-xOwTGREqCx55nb60JXJ29g&scope=CUSTOMERS_WRITE+CUSTOMERS_READ+PAYMENTS_READ+PAYMENTS_WRITE+ITEMS_READ+ITEMS_WRITE+INVENTORY_READ+INVENTORY_WRITE+ORDERS_READ+ORDERS_WRITE+MERCHANT_PROFILE_READ+MERCHANT_PROFILE_WRITE+LOYALTY_READ+LOYALTY_WRITE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+&session=false&state=" +
          this.firebaseRefs.organization.id +
          "|NEWENTRY|" +
          randomVal,
        "_blank"
      )
      setTimeout(() => {
        this.needsRefresh = true
        this.needsRefreshNew = true
      }, 500)
    },
    importAllFromSquare(restaurant) {
      const restaurantId = restaurant.id
      if (
        confirm(
          "Are you sure you want to sync everything from Square into this restaurant?" +
            " This will overwrite any existing data with the data on Square."
        )
      ) {
        const restaurantChannel = this.getChannelForRestaurant(restaurantId)
        if (restaurantChannel) {
          const accessToken = restaurantChannel.accessToken
          const orgID = this.firebaseRefs.organization.id
          window.open(
            "https://us-central1-nextgenkitchens-e9608.cloudfunctions.net/dataCommitV2?token=" +
              accessToken +
              "&id=" +
              orgID,
            "_blank"
          )
          setTimeout(() => {
            this.needsRefresh = true
          }, 500)
        }
      }
    },
    importMenuItemsFromSquare(restaurant) {
      const restaurantId = restaurant.id
      const restaurantChannel = this.getChannelForRestaurant(restaurantId)
      if (restaurantChannel) {
        const merchant = restaurantChannel.restaurantData[0].merchantId
        window.open(
          "https://us-central1-nextgenkitchens-e9608.cloudfunctions.net/syncDataFromSquare?state=1&merchant=" +
            merchant,
          "_blank"
        )
      }
    },
    getChannelForRestaurant(restaurantId) {
      if (!this.channels) {
        return
      }
      var restaurantChannel = this.channels.find(channel => {
        return channel.restaurantData
          ? channel.restaurantData[0].restaurantId === restaurantId
          : false
      })
      return restaurantChannel
    },
    importPossible(restaurantId) {
      if (this.channels.length > 0) {
        var channel = {}
        try {
          channel = this.channels.find(
            channel =>
              channel.restaurantData &&
              channel.restaurantData[0].restaurantId == restaurantId
          )
          return channel != undefined && "accessToken" in channel
        } catch (e) {
          throw new Error(e)
        }
      }
      return false
    },
  },
}
</script>

<style></style>
