<template>
  <div v-if="$route.params.tab == 'channels'">
    <page-component
      pageTitle="Payment Channels"
      :restaurantFilterAvailable="true"
      :headers="headers"
      :items="channels"
      :loadingData="false"
      :buttons="buttons"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name ? item.name : "N/A" }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{
          item.type
            ? item.type[0].toUpperCase() + item.type.slice(1)
            : item.name === "SquareRestaurant"
            ? "Square"
            : "Other"
        }}
      </template>
      <template v-slot:[`item.internal`]="{ item }">
        <v-icon v-if="item.internal">mdi-check</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </template>
      <template v-slot:[`item.lastUpdated`]="{ item }">
        {{ formatDate(item) }}
      </template>
      <template v-slot:[`item.restaurants`]="{ item }">
        <span v-if="item.restaurantIds">
          {{ restaurantNames(item.restaurantIds) }}
        </span>
        <span v-else-if="item.restaurantId">
          {{ getRestaurantName(item.restaurantId) }}
        </span>
        <span v-else-if="item.restaurantData">
          {{ getRestaurantName(item.restaurantData.map(data => data.restaurantId)) }}
        </span>
        <span v-else>{{ item.organizationAccount ? "Organization" : "N/A" }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn
          v-if="item.name && !item.internal"
          type="edit"
          @click="editItem(item)"
        />
      </template>
    </page-component>
    <add-edit-form :paymentProviders="paymentProviders" @close="formClosed()" />
    <v-dialog v-model="needsRefresh" persistent retain-focus width="500">
      <v-card>
        <v-card-title>Done</v-card-title>
        <v-card-text class="pb-6">
          <p>
            Your channel has been successfully added to Restaurant Manager. Please
            press the button below to update the page.
          </p>
          <v-btn color="primary" :loading="awaitingResponse" @click="refreshData()">
            Continue
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import moment from "moment"
import AddEditForm from "./AddEditForm.vue"
import PageComponent from "../../shared/PageComponent.vue"

export default {
  name: "channels",
  components: { PageComponent, AddEditForm },
  data() {
    return {
      headers: [
        { text: "Type", value: "type" },
        // { text: "Internal", value: "internal" },
        // { text: "Last Updated", value: "lastUpdated" },
        { text: "Restaurant(s)", value: "restaurants" },
        { text: "Account Id", value: "accountId" },
        // { text: "Actions", value: "actions" },
      ],
      awaitingResponse: false,
      needsRefresh: false,
      paymentProviders: ["Stripe"],
    }
  },
  computed: {
    ...mapGetters(["getRestaurantName"]),
    ...mapState(["channels", "restaurants", "settings"]),
    buttons() {
      if (this.paymentProviders.length && this.restaurantsWithoutChannels.length) {
        return [
          {
            text: "+ Add New Payment Channel",
            to: { params: { form: "AddEditForm" } },
          },
        ]
      } else return []
    },
    restaurantsWithoutChannels() {
      return this.restaurants.filter(
        restaurant =>
          !this.channels.some(channel => channel.restaurantId === restaurant.id)
      )
    },
  },
  mounted() {
    if (!this.settings.stripeSecretKey) {
      // checks for existence of an organization Stripe account, if not, remove Stripe as an option
      const index = this.paymentProviders.indexOf("Stripe")
      if (index > -1) {
        this.paymentProviders.splice(index, 1)
      }
    }
  },
  methods: {
    ...mapActions(["fetchChannels"]),
    formatDate(channel) {
      return moment(channel.lastUpdated, "String").format("MMMM Do YYYY LT")
    },
    restaurantNames(channel) {
      if (channel.restaurantData?.length > 0) {
        var restaurantNames = []
        channel.restaurantData.forEach(resto => {
          restaurantNames.push(this.getRestaurantName(resto.restaurantId))
        })
        return restaurantNames.join(", ")
      }
      return "All Restaurants"
    },
    async editItem(channel) {
      await this.$store.commit("setItemToEdit", channel)
      this.$router.push({ params: { form: "AddEditForm", id: channel.id } })
    },
    formClosed() {
      this.fetchChannels()
      setTimeout(() => {
        this.needsRefresh = true
      }, 500)
    },
    async refreshData() {
      this.awaitingResponse = true

      await this.fetchChannels()

      this.needsRefresh = false
      this.awaitingResponse = false
    },
  },
}
</script>
