<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'AddEditForm'"
      title="Restaurant"
      :editMode="editMode"
      :isCloseDiabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      :error="mainErrorMessage"
      @close="closeForm()"
      @submit="submitHandle"
    >
      <h3>Restaurant Information</h3>
      <v-row justify="start" class="mt-3">
        <v-col cols="4">
          <v-text-field
            v-model="name"
            label="Restaurant Name"
            outlined
            :disabled="!fieldsEnabled"
            :error-messages="getFormErrors($v, 'name')"
          />
        </v-col>
        <v-col cols="4">
          <div v-if="editAddress">
            <v-text-field
              v-model="address.addressLine1"
              label="Address Line 1"
              outlined
              :disabled="!fieldsEnabled"
              :error-messages="getFormErrors($v, 'address.addressLine1')"
            />
            <v-text-field
              v-model="address.addressLine2"
              label="Address Line 2"
              outlined
              :disabled="!fieldsEnabled"
              :error-messages="getFormErrors($v, 'address.addressLine2')"
            />
            <v-text-field
              v-model="address.city"
              label="City"
              outlined
              :disabled="!fieldsEnabled"
              :error-messages="getFormErrors($v, 'address.city')"
            />
            <v-text-field
              v-model="address.provinceState"
              label="Province/State (2-letter code)"
              outlined
              :disabled="!fieldsEnabled"
              :error-messages="getFormErrors($v, 'address.provinceState')"
            />
            <v-text-field
              v-model="address.postCode"
              label="Postal Code (no spaces)"
              outlined
              :disabled="!fieldsEnabled"
              :error-messages="getFormErrors($v, 'address.postCode')"
            />
            <v-text-field
              v-model="address.country"
              label="Country (2-letter code)"
              outlined
              :disabled="!fieldsEnabled"
              :error-messages="getFormErrors($v, 'address.country')"
            />
            <v-text-field
              v-model="address.latitude"
              label="Latitude"
              outlined
              :disabled="!fieldsEnabled"
              :error-messages="getFormErrors($v, 'address.latitude')"
            />
            <v-text-field
              v-model="address.longitude"
              label="Longitude"
              outlined
              :disabled="!fieldsEnabled"
              :error-messages="getFormErrors($v, 'address.longitude')"
            />
          </div>
          <v-text-field
            v-else
            :value="
              address.addressLine1 +
              ' ' +
              address.city +
              ' ' +
              address.provinceState +
              ' ' +
              address.postCode +
              ' ' +
              address.country
            "
            label="Address"
            outlined
            readonly
            @click="editAddress = true"
          />
          <!-- <vuetify-google-autocomplete
            id="map"
            ref="address"
            label="Address"
            outlined
            :disabled="!fieldsEnabled"
            :value="
              address.addressLine1.length > 0
                ? [
                    address.addressLine1,
                    address.city,
                    address.provinceState,
                    address.postCode,
                    address.country,
                  ].join(', ')
                : ''
            "
            country="CA"
            :error-messages="getFormErrors($v, 'address')"
            @placechanged="getAddressData"
          /> -->
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="prepTime"
            label="Prep Time"
            suffix="min"
            outlined
            number
            :error-messages="getFormErrors($v, 'prepTime')"
          />
        </v-col>
        <v-col v-if="false" cols="4">
          <v-text-field
            v-model="storeNumber"
            label="Store Number"
            outlined
            number
            :disabled="!fieldsEnabled"
            :error-messages="getFormErrors($v, 'storeNumber')"
          />
        </v-col>
        <v-col v-if="false" cols="3">
          <v-menu :close-on-content-click="false" :offset-y="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="pa-7" v-bind="attrs" v-on="on">
                + Features
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="enabledFeatures.delivery" />
                </v-list-item-action>
                <v-list-item-title>Delivery</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="enabledFeatures.multiLocation" />
                </v-list-item-action>
                <v-list-item-title>Multi Location</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch />
                </v-list-item-action>
                <v-list-item-title>Auto-sync with Square</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <h3 class="my-5 mb-10 text-center">Logo</h3>
          <custom-image-upload
            style="max-width: 250px"
            class="mx-auto"
            name="logo"
            :imageUrl="images.logo"
            @imageProcessed="images.logo = $event"
          />
        </v-col>
        <v-col cols="12" md="8">
          <h3 class="my-5 mb-10 text-center">Hero Image</h3>
          <custom-image-upload
            name="hero"
            :imageUrl="images.hero"
            @imageProcessed="images.hero = $event"
          />
        </v-col>
      </v-row>
      <h3 class="my-5">Social Media</h3>
      <v-row justify="start" class="mt-3">
        <v-col cols="4">
          <v-select
            v-model="social.type"
            :items="socialTypes"
            item-value="id"
            item-text="name"
            label="Select Platform"
            outlined
            :error-messages="getFormErrors($v, 'social.type')"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="social.handle"
            label="Handle"
            outlined
            :error-messages="getFormErrors($v, 'social.handle')"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="social.url"
            label="URL"
            outlined
            :error-messages="getFormErrors($v, 'social.url')"
          />
        </v-col>
      </v-row>
      <h3 class="mb-4">Hours</h3>
      <weekly-hours
        v-model="hours"
        :error-messages="getFormErrors($v, 'hours')"
        :disabled="!fieldsEnabled"
      />
    </form-dialog>
  </div>
</template>
<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { mapState, mapActions, mapGetters } from "vuex"
import CustomImageUpload from "../../shared/CustomImageUpload.vue"
import WeeklyHours from "../../shared/forms/datetime/WeeklyHours.vue"
import { required, numeric } from "vuelidate/lib/validators"
import datetimeMixins from "@/AuthenticatedContent/shared/forms/datetime/mixins.js"

export default {
  name: "add-edit-form",
  components: {
    FormDialog,
    CustomImageUpload,
    WeeklyHours,
  },
  mixins: [datetimeMixins],
  data() {
    return {
      name: "",
      id: null,
      storeNumber: "",
      prepTime: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: "",
        latitude: "",
        longitude: "",
        postCode: "",
        provinceState: "",
      },
      editAddress: this.editMode,
      hours: {},
      enabledFeatures: {
        delivery: false,
        multiLocation: false,
      },
      images: {
        logo: "",
        hero: "",
      },
      social: {
        type: "",
        handle: "",
        url: "",
      },
      isDeleted: false,
      isEnabled: true,
      closeDisabled: false,
      awaitingResponse: false,
      socialTypes: [
        { name: "Facebook", id: "facebook" },
        { name: "Twitter", id: "twitter" },
        { name: "Instagram", id: "instagram" },
      ],
      mainErrorMessage: "",
    }
  },
  validations() {
    return {
      name: { required },
      storeNumber: {},
      prepTime: { numeric },
      address: {},
      hours: {},
      enabledFeatures: {},
      social: {},
    }
  },
  computed: {
    ...mapState["restaurants"],
    ...mapGetters(["getItemToEdit", "getRestaurantSyncStatus"]),
    editMode() {
      return this.itemToEdit != null
    },
    itemToEdit() {
      return this.getItemToEdit
    },
    fieldsEnabled() {
      if (!this.editMode) {
        return true
      }
      return !this.getRestaurantSyncStatus(this.itemToEdit.id)
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.closeForm()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["updateRestaurants", "newRestaurant"]),
    async submitHandle() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.closeDisabled = this.awaitingResponse = true
        const fields = {
          id: "string",
          name: "string",
          storeNumber: "string",
          prepTime: "number",
          address: "object",
          hours: "object",
          enabledFeatures: "object",
          social: "object",
          images: "object",
          isEnabled: "boolean",
          isDeleted: "boolean",
        }
        var payload = {}
        for (const [field, type] of Object.entries(fields)) {
          switch (type) {
            case "number":
              payload[field] = parseFloat(this[field])
              break
            case "boolean":
              payload[field] = !!this[field]
              break
            case "object":
              payload[field] = this[field]
              break
            case "array":
              payload[field] = this[field]?.length > 0 ? this[field] : []
              break
            default:
              if (this[field] !== null && this[field] !== undefined) {
                payload[field] = this[field]
              } else {
                payload[field] = ""
              }
              break
          }
        }
        if (this.editMode) {
          await this.updateRestaurants(payload)
            .then(() => {
              this.closeForm()
            })
            .catch(err => {
              this.mainErrorMessage = err
            })
        } else if (!this.editMode) {
          await this.newRestaurant(payload)
            .then(() => {
              this.closeForm()
            })
            .catch(err => {
              this.mainErrorMessage = err
            })
        }
      }
    },
    closeForm() {
      this.$router.push({ params: { form: null, id: null } })
    },
    getAddressData(addy) {
      this.address.addressLine1 = addy.street_number + " " + addy.route
      this.address.city = addy.locality
      this.address.country = addy.country
      this.address.postCode = addy.postal_code
      this.address.provinceState = addy.administrative_area_level_1
      this.address.latitude = addy.latitude
      this.address.longitude = addy.longitude
    },
    prepEdit() {
      this.name = this.itemToEdit.name
      this.storeNumber = this.itemToEdit.storeNumber
      this.prepTime = this.itemToEdit.prepTime
      this.address = this.itemToEdit.address
      this.id = this.itemToEdit.id
      this.isEnabled = this.itemToEdit.isEnabled
      this.enabledFeatures = this.itemToEdit.enabledFeatures
      this.social = this.itemToEdit.social
        ? this.itemToEdit.social
        : {
            type: "",
            handle: "",
            url: "",
          }
      this.images = this.itemToEdit.images
        ? this.itemToEdit.images
        : {
            logo: "",
            hero: "",
          }
      this.hours = this.itemToEdit.hours
        ? this.itemToEdit.hours
        : {
            sun: [this.getEmptyDateRange()],
            mon: [this.getEmptyDateRange()],
            tue: [this.getEmptyDateRange()],
            wed: [this.getEmptyDateRange()],
            thu: [this.getEmptyDateRange()],
            fri: [this.getEmptyDateRange()],
            sat: [this.getEmptyDateRange()],
          }
    },
  },
}
</script>
